<template>
  
  <div class="sform">

    <UiTextArea  
      :placeholder="$t('week_view_question_placeholder')"       
      :text="vl"
      @change:text="vl = $event"
      />
      
    <GrowerNotVerificated 
      v-if="useAuth().isAuth() && !useAuth().isVerifiedEmail()"/>

    <UiButton
      :name="$t('universal_button_send')"
      :type="'primary'"
      :disabled="useAuth().isAuth() && !useAuth().isVerifiedEmail()"
      @click="save"
      :is-loading="isLoading"
      />        

      
     
  </div>
  
</template>

<script setup>

const emits = defineEmits(['created']);
const { $api, $popup } = useNuxtApp()
const props = defineProps({
  question: {
    type: Number,
    required: true
  },
  solution: {
    type: Object,
    required: false
  }
})

const max = ref(280);
const current = ref(0);
const vl = ref('');
const isLoading = ref(false);

const save = function(){

  isLoading.value = true;

  if(!props.solution?.id){
    $api.postQuestionSolution(props.question, {
      text: vl.value
    }) 
    .then((res) => {     
      isLoading.value = false;    
      $popup.success('Created');   
      emits('created', res.data);
    })
    .catch(function (error) { 
      isLoading.value = false;
      $popup.error(error);   
    });  
  }else{
    $api.patchQuestionSolution(props.solution.id, {
      text: vl.value
    }) 
    .then((res) => {      
      isLoading.value = false;      
      $popup.success('Created');   
      emits('created', res.data);
    })
    .catch(function (error) { 
      isLoading.value = false;
      $popup.error(error);             
    });
  }
}

 
</script>

<style scoped>
 
.sform {
  
    margin-left: 60px;
    margin-top: 20px;
}

</style>
